@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');

.Botao {
display: flex;
justify-content: center;
}

.button-colabore {
font-family: 'Sarala', sans-serif;
margin: 50px; 
width: 314px; 
height: 84px; 
background-color: transparent;
font-size: 30px;
border-color: #D7C37A;
border-width: 8px;
border: 8px solid #D7C37A;
box-shadow: none;
color: #BCA44A;
outline: none;
font-weight: bold;
margin-left: 10px;
}
.button-colabore:hover {
    transform: scale(1.1);
}
