@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');

.containerHeader {
  position: relative;
 
}

.Header {
    background-color: #55A44E;
    width: 100%;
    height: 250px;
}
.img {
    width: 170px;
    height: 170px;
}
.img-container {
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center;
    margin-top: -32px;
  }

  .img-container .img {
    margin-bottom: -29px; 
}
.img:hover {
  transform: scale(1.1);
}
  .menu {
    display: flex;
    justify-content: center;
    font-family: 'Sarala', sans-serif;
  }
  
  .menu ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu li {
    font-size: 20px;
    margin-right: 70px;
    letter-spacing: 6px; 
  }
  
  .menu a {
    text-decoration: none;  
    color: #FFFFFF;

  }
  .menu li :hover{
    color: #BCA44A;
  }
  .menu-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
  }
  
  .navbar-color { 
    background-color: #55A44E;
  }