.main {
  max-width: 800px; /* Define a largura máxima para evitar que o conteúdo se estenda demais em telas grandes */
  margin: 0 auto; /* Centraliza o conteúdo horizontalmente */
  padding: 20px;
}
.mainContainer {
  max-width: 800px;
  margin: 0;
  padding: 0;
}
.img-container2 {
  max-width: 800px;
  overflow: hidden; /* esconde qualquer conteúdo excedente */
  height: 450px;
  object-fit: cover;
}
.title-quem-somos {
  text-align: center;
  color: #BCA44A;
  font-size: 55px;
}

.title-sobre-iniciativa {
  text-align: center;
  color: #BCA44A;
  font-size: 55px;
}

.title-que-vai-cesta {
  text-align: center;
  color: #BCA44A;
  font-size: 55px;
}

.introducao {
  text-align: center;
  font-size: 35px;
  color: #1B8057;
  padding: 55px;
}
.png {
  position: absolute;
  top: 170%;
  right: 67%;
  width: 420px;
  height: 420px;
}

.containerBotaoMain {
  margin-top: 90px;
}

.botaoMain {
  position: relative;
}


