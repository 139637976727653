.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #55A44E;
  height: 170px;
  position: relative;
}

.img-container3 {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.img {
  width: 200px;
  height: 200px;
}

.icones {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  padding: 50px;
  margin-right: 10px;
}
.icones img {
  margin-right: 20px;
}



